import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, colors, space } from 'theme'
import { CONTAINER_MAX_WIDTH, DOWNLOAD_APP_GOOGLE_LINK, DOWNLOAD_APP_APPLE_LINK } from 'consts'
import SEO from 'components/SEO'
import { Flex, Grid, Box } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import G2FeaturesComponent from 'components/G2FeaturesComponent'

import imageHeader from 'static/images/mobile_app/header_picture.webp'
import imageSpeedUp from 'static/images/mobile_app/speed_up.webp'
import imageDeliverMoreValue from 'static/images/mobile_app/deliver_more_value.webp'
import imagePostScheduleAndPlan from 'static/images/mobile_app/post_schedule_and_plan.webp'
import imageInboxFeatures from 'static/images/mobile_app/inbox_features.webp'
import downloadGoogle from 'static/svg/download-google-play.svg'
import downloadIOS from 'static/svg/download-app-store.svg'

import speedUpImageAIAssistant from 'static/images/mobile_app/speed_up/ai_assistant.svg'
import speedUpImageSchedulingShortcuts from 'static/images/mobile_app/speed_up/scheduling_shortcuts.svg'
import speedUpImageRepurposeContent from 'static/images/mobile_app/speed_up/repurpose_content.svg'
import speedUpImageGetInspired from 'static/images/mobile_app/speed_up/get_inspired.svg'

import deliverMoreValueImageProducts from 'static/images/mobile_app/deliver_more_value/products.svg'
import deliverMoreValueImageHashtags from 'static/images/mobile_app/deliver_more_value/hashtags.svg'
import deliverMoreValueImageOptimalTime from 'static/images/mobile_app/deliver_more_value/optimal_time.svg'

import CarouselReviewSection from 'routes/Home/components/CarouselReviewSection'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const DownloadImg = styled.img`
  padding-right: ${space.xs};
  padding-left: ${space.xs};
  cursor: pointer;
`

const SPEED_UP_ITEMS = [
  {
    picture_url: speedUpImageAIAssistant,
    title: 'AI Assistant',
    description: 'Generate fantastical posts in seconds using the power of ChatGPT.',
  },
  {
    picture_url: speedUpImageSchedulingShortcuts,
    title: 'Scheduling shortcuts',
    description: 'Use quick presets to easily add posts to your queues or simply publish right away.',
  },
  {
    picture_url: speedUpImageRepurposeContent,
    title: 'Repurpose content',
    description: 'Copy and repurpose existing posts or draft new ones using saved captions.',
  },
  {
    picture_url: speedUpImageGetInspired,
    title: 'Get inspired',
    description: 'Use built-in AI Assistant to create posts that generate engagement.',
  },
]

const DELIVER_MORE_VALUE_ITEMS = [
  {
    picture_url: deliverMoreValueImageProducts,
    title: 'Tag products, people and locations',
    description: 'Increase reach and engagement by tagging shop products, people and locations in posts',
  },
  {
    picture_url: deliverMoreValueImageHashtags,
    title: 'Hashtag suggestions',
    description: 'Include best possible hashtags using hashtag suggestion tool',
  },
  {
    picture_url: deliverMoreValueImageOptimalTime,
    title: 'Optimal time',
    description: 'Quickly schedule using suggested optimal times for each profile',
  },
]

const MobileApp = () => {
  return (
    <App fullHeader>
      <SEO
        title="Post, Schedule & Plan from Anywhere"
        description="Download Vista Social’s mobile app and save time managing all of your social media channels anytime, no matter where you are."
        path="/mobile/"
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex mb={{ mobile: 'l', desktop: 'xl' }} flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex mb="l" width="100%" justifyContent="center" alignItems="center">
                <Flex width="100%" justifyContent="center" flexDirection="column">
                  <Flex flexDirection={{ mobile: 'row', desktop: 'column' }} flexWrap="wrap" justifyContent="center">
                    <H1
                      fontSize="4xl"
                      fontWeight="bold"
                      color={COLOR_CONSTANTS.DENIM}
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                    >
                      Post, Schedule & Plan{' '}
                      <H1
                        fontSize="4xl"
                        fontWeight="bold"
                        color="primary"
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        from Anywhere
                      </H1>
                    </H1>
                  </Flex>
                  <Flex mt="m" pt="s" maxWidth={{ mobile: '100%', desktop: '480px' }}>
                    <H2
                      color="secondaryText"
                      fontSize="l"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                    >
                      Download Vista Social’s mobile app and save time managing all of your social media channels
                      anytime, no matter where you are.
                    </H2>
                  </Flex>

                  <Flex
                    mt="m"
                    pt="s"
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                  >
                    <Text as="a" href={DOWNLOAD_APP_GOOGLE_LINK} target="_new" rel="noopener noreferrer nofollow">
                      <DownloadImg src={downloadGoogle} />
                    </Text>

                    <Text as="a" href={DOWNLOAD_APP_APPLE_LINK} target="_new" rel="noopener noreferrer nofollow">
                      <DownloadImg src={downloadIOS} />
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }}>
                <LocalImage
                  src={imageHeader}
                  width="100%"
                  mx="auto"
                  maxWidth="659px"
                  alt="Post, Schedule & Plan from Anywhere"
                />
              </Flex>
            </Grid>
            <Flex mt="m" pt="m" flexDirection="column" alignItems="center" mb={{ mobile: 's', desktop: 's' }}>
              <G2FeaturesComponent />
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="750px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Post, Schedule and Plan{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                from Your Phone
              </H1>
            </H3>
            <Text mt="m" pt="s" color="secondaryText" fontSize="l">
              Access all Vista Social content planning features on the go.
            </Text>
          </Flex>

          <Flex mt="xl" justifyContent={{ mobile: 'center', desktop: 'space-between' }} as="a" href="/pricing">
            <LocalImage
              src={imagePostScheduleAndPlan}
              maxWidth="1008px"
              width="100%"
              mx="auto"
              alt="Post, Schedule and Plan from Your Phone"
            />
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 2 }} maxWidth="494px" width="100%">
              <LocalImage src={imageSpeedUp} width="100%" alt="Speed up your work" />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 1 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
              maxWidth="545px"
            >
              <Text
                fontWeight="bold"
                fontSize="3xl"
                color={COLOR_CONSTANTS.DENIM}
                textAlign={{ mobile: 'center', desktop: 'left' }}
              >
                <Text as="span" fontSize="3xl" fontWeight="bold" color="primary">
                  Speed up{' '}
                </Text>
                your work
              </Text>

              <Text mt="m" color="secondaryText" fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
                Schedule faster, better content, all the time
              </Text>
              <Grid
                mt="l"
                pt="m"
                gridGap="l"
                gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', mobileLarge: 'repeat(2, 1fr)' }}
              >
                {SPEED_UP_ITEMS.map(({ picture_url, title, description }) => (
                  <Flex flexDirection="column" key={title} width="100%">
                    <Flex alignItems="center">
                      <LocalImage src={picture_url} width="44px" height="44px" alt={title} />
                      <Text ml="m" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="l">
                        {title}
                      </Text>
                    </Flex>
                    <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                      {description}
                    </Text>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="750px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Harness the Power of{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                AI Reply Assistant
              </H1>
            </H3>
            <Text mt="m" pt="s" color="secondaryText" fontSize="l">
              Stay on top with smart inbox for quick responses across social profiles.
            </Text>
          </Flex>

          <Flex mt="xl" justifyContent={{ mobile: 'center', desktop: 'space-between' }} as="a" href="/pricing">
            <LocalImage
              src={imageInboxFeatures}
              maxWidth="1031px"
              width="100%"
              mx="auto"
              alt="Harness the Power of AI Reply Assistant"
            />
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg="radial-gradient(51.88% 56.86% at 31.54% 45.31%, #0063E3 36.77%, #0250C9 100%)">
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth="1083px"
          width="100%"
          height="100%"
        >
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 1 }} maxWidth="500px" width="100%">
              <LocalImage src={imageDeliverMoreValue} width="100%" alt="Deliver more value" />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 2 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
              maxWidth="418px"
            >
              <Text
                fontWeight="bold"
                fontSize="3xl"
                color={COLOR_CONSTANTS.WHITE}
                textAlign={{ mobile: 'center', desktop: 'left' }}
              >
                Deliver more value
              </Text>

              <Text mt="m" color="white" fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
                Create best possible posts for each network
              </Text>
              <Grid mt="l" pt="m" gridGap="l" gridTemplateColumns="repeat(1, 1fr)">
                {DELIVER_MORE_VALUE_ITEMS.map(({ picture_url, title, description }) => (
                  <Flex flexDirection="column" key={title} maxWidth={{ mobile: '100%', tablet: '391px' }} width="100%">
                    <Flex alignItems="center">
                      <LocalImage src={picture_url} width="44px" height="44px" alt={title} />
                      <Text ml="m" color="white" fontWeight="bold" fontSize="l">
                        {title}
                      </Text>
                    </Flex>
                    <Text mt="m" fontSize="m" color="white" textAlign="left">
                      {description}
                    </Text>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <CarouselReviewSection bg={COLOR_CONSTANTS.WHITE} />

      <Footer />
    </App>
  )
}

export default MobileApp
